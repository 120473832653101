import {useEffect, useState} from "react";
import {Card, CardBody, CardFooter, Checkbox, 
    Button, Alert, Typography, Dialog, DialogHeader, DialogBody, DialogFooter} from '@material-tailwind/react';
import {Form, Formik} from 'formik';
import FormikInputIcon from "../../components/form/formik-input-icon";
import * as Yup from 'yup';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, Link} from "react-router-dom";
import {authSelector, fetchSession, login, totpSetup, totpVerify} from "../../store/auth";
import Image from 'components/image';
import logo from 'assets/img/logos/gobby-anim.gif';
import { fetcherSimple } from "utils/api";
import FormikInput from "components/form/formik-input";
import { toast } from "react-toastify";
import {QRCodeSVG} from 'qrcode.react';

export default function Login() {
    const auth = useSelector(authSelector)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showTOTPDialog, setShowTOTPDialog] = useState(false);
    const [showSetupTOTPDialog, setShowSetupTOTPDialog] = useState(false);
    const [secret32, setSecret32] = useState(null);
    const [secretCode, setSecretCode] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [userEmail, setUserEmail] = useState(null);

    const handleLoginSubmit = async (values) => {
        try {
            const loginResponse = await dispatch(login(values)).unwrap();
            console.log(loginResponse.next);
            if (loginResponse.next && loginResponse.next === 'SETUP_TOTP') {
                const response = await fetcherSimple('/v2/auth/setup-totp', 'GET');
                if (response.secret) {
                    setUserEmail(values.email);
                    setSecret32(response.secret32);
                    setSecretCode(response.secret);
                    setShowSetupTOTPDialog(true);
                }
            } else if (loginResponse.next && loginResponse.next === 'CHALLENGE_TOTP') {
                setShowTOTPDialog(true);
            }
        } catch (rejectedValueOrSerializedError) {
            console.log('rejectedValueOrSerializedError');
            toast.error('Error with logging in to server');
        }
    }

    const handleTOTPVerifySubmit = async (values) => {
        dispatch(totpVerify(values));
    }

    const handleTOTPSetupSubmit = async (values) => {
        const submitValues = {
            secret: secretCode,
            code: values.code
        }
        dispatch(totpSetup(submitValues));
    }

    const initialLoginValues = {
        email: '',
        password: '',
    }

    const initialTOTPVerifyValues = {
        code: '',
    }

    const initialTOTPSetupValues = {
        code: '',
    }

    const validationSchemaLogin = Yup.object().shape({
        email: Yup.string().required('Please enter a valid login'),
        password: Yup.string().required('Please provide a password'),
    });

    const validationSchemaVerifyTOTP = Yup.object().shape({
        code: Yup.string().required('Authentication Code Required'),
    });

    const validationSchemaSetupTOTP = Yup.object().shape({
        code: Yup.string().required('Authentication Code Required'),
    });

    useEffect(() => {
        // Redirect if logged in, else fetch session and see if logged in
        if (auth.status === 'loggedIn') {
            navigate('/');
        } else if (auth.status === 'unknown') {
            dispatch(fetchSession());
        }
    }, [auth.status, dispatch, navigate]);

    return (
        <>
        <div className="flex flex-col min-w-screen min-h-screen bg-white">
            <div className='basis-0 h-[200px] md:basis-1/3 justify-left items-start pl-6 pt-3'>
            <Image className="sm:w-[200px] w-[0px]" src={logo} rounded={false} raised={false} alt={"Gobby Logo"}/>
            </div>
            <div className="flex min-h-fit justify-center">
                <Card className="filter-none bg-blue-gray-100 p-4" shadow={true}>
                    <Typography className="font-display text-center md:text-xl lg:content-center md:mt-2" color="black">
                        Sign in to Start Using Gobby®
                    </Typography>                   
                    <Formik initialValues={initialLoginValues} onSubmit={handleLoginSubmit} validationSchema={validationSchemaLogin}>
                        {({isValid, dirty}) => (
                            <Form>
                                <CardBody className="font-body mt-4 px-2 py-2">
                                    <div className="mb-2 px-4 py-2 bg-white rounded-xl">
                                        <Typography as="div" className="py-4">
                                            <FormikInputIcon variant="standard" className="font-body" name="email" type="email" 
                                                color="gray" style={{"borderRadius": 0}} label="Email" iconName="email" 
                                                required/>
                                        </Typography>
                                        <Typography as="div" className="pb-1">
                                            <FormikInputIcon name="password" className="font-display" variant="standard" 
                                                type={showPassword ? 'text' : 'password'} color="gray" style={{"borderRadius": 0}} label="Password" 
                                                iconName="lock" required/>
                                        </Typography>
                                        <Typography as="div" className="text-sm font-body text-right">
                                            <Checkbox 
                                                checked={showPassword}
                                                label="Show Password" id="showpassword"
                                                onChange={()=>{setShowPassword(showPassword=>!showPassword)}}
                                            />
                                            {auth.error && (
                                                <Alert color={"red"}>{auth.errorMessage}</Alert>
                                            )}
                                        </Typography>
                                    <Typography className="text-center  border border-blue-gray-700 rounded-full my-2 hover:border-blue-500 shadow">
                                        <Link className='text-blue-gray-800 hover:text-blue-500 hover:font-bold font-body' to='/resetpassword'>
                                            Forgot your details? Click here.
                                        </Link>
                                    </Typography>
                                    </div>
                                </CardBody>
                                <CardFooter className="p-0">
                                    <div className="flex justify-center">
                                        <Button type="submit" color={isValid && dirty ? "green" : "gray"}
                                                variant="filled"  
                                                className="rounded-full h-24 w-24 font-display text-2xl text-white hover:border-white hover:border"
                                                disabled={!(isValid && dirty)}>
                                            Go
                                        </Button>
                                    </div>
                                    <Typography className="flex justify-center mt-4 bg-bb font-body text-blue-gray-700 font-normal">
                                        New to Gobby®?
                                    </Typography>
                                    <Typography className="flex justify-center bg-bb">
                                        <Link className='text-black rounded-full bg-white ml-2 px-4 
                                                        hover:bg-gray-100 hover:border-blue-500 hover:border border-blue-gray-700 border 
                                                        hover:text-blue-500 font-body font-normal hover:font-bold' to='/resetpassword'>
                                            Create a New Account
                                        </Link>
                                    </Typography>
                                </CardFooter>
                            </Form>
                        )}
                    </Formik>

                </Card>
            </div>
        </div>
        <Dialog open={showSetupTOTPDialog} handler={() => setShowSetupTOTPDialog(showSetupTOTPDialog=>!showSetupTOTPDialog)}>
            <DialogHeader className="bg-gray-300 text-black font-display rounded-t-lg min-w-full">
                Set Up Two-Factor Authentication
            </DialogHeader>
            <Formik initialValues={initialTOTPSetupValues}
                    onSubmit={handleTOTPSetupSubmit}
                    validationSchema={validationSchemaSetupTOTP}>
                {({isValid, dirty}) => (
                    <Form>
                        <DialogBody divider className="mb-7 overflow-y-auto max-h-[80vh]">
                            {((secret32 && userEmail) &&
                                <QRCodeSVG value={`otpauth://totp/Gobby:${userEmail}?secret=${secret32}&issuer=Gobby`} />
                            )}
                            <Typography className="font-body">
                                {secretCode ? `Either scan the QR Code or enter as a Secret key into the Authenticator App: ${secretCode}` : 'Secret Code Loading or Unavailable'}
                            </Typography>
                            <div className="px-4 font-body">
                            <FormikInput name="code" autoComplete="off" className="font-display bg-white" type="text" color="indigo"
                                    label="Two Factor Authentication Code" required/>
                            </div>
                        </DialogBody>
                        <DialogFooter>
                            <div className='absolute bottom-2 right-6'>
                                <Button type="submit" color={isValid && dirty ? "indigo" : "gray"}
                                        variant="filled"
                                        className='bg-white rounded-full hover:text-blue-500 hover:border-blue-500 hover:text-blue-500 border-white border'  
                                        disabled={!(isValid && dirty)}
                                        onClick={() => setShowSetupTOTPDialog(false)}>
                                    Submit
                                </Button>
                            </div>
                        </DialogFooter>
                    </Form>
                )}
            </Formik>
        </Dialog>
        <Dialog open={showTOTPDialog} handler={() => setShowTOTPDialog(showTOTPDialog=>!showTOTPDialog)} className='text-center max-w-fit min-w-fit rounded-xl flex flex-col items-center'>
            <DialogHeader className="bg-gray-300 text-black font-display rounded-t-lg min-w-full">
                Verify Your Identity
            </DialogHeader>
            <Formik initialValues={initialTOTPVerifyValues}
                    onSubmit={handleTOTPVerifySubmit}
                    validationSchema={validationSchemaVerifyTOTP}>
                {({isValid, dirty}) => (
                    <Form>
                        <DialogBody className="mb-7 font-body overflow-y-auto max-h-[80vh] md:px-14">
                            <div className="font-body">
                            <Typography className='font-body text-sm pb-4 mt-2'>
                                Enter the passcode from your Google Authenticator application
                            </Typography>
                                <FormikInput autoComplete="off" className="font-display text-lg bg-white" name="code" type="text" color="indigo"
                                    label="Google Authenticator Number" required/>
                            </div>
                        </DialogBody>
                        <DialogFooter>
                            <div className="absolute bottom-4 right-4">
                                <Button type="button" 
                                        className="
                                        bg-white
                                        rounded-full 
                                        text-lg 
                                        px-4 py-1
                                        mr-4
                                        hover:bg-red-500
                                        hover:text-white
                                        hover:border-blue-500
                                        font-body normal-case
                                        text-black
                                        font-thin
                                        border
                                        border-black"
                                        variant="filled" 
                                        onClick={() => setShowTOTPDialog(false)}>
                                    cancel
                                </Button>
                                <Button type="submit" color={isValid && dirty ? "indigo" : "gray"}
                                        className="
                                        bg-white
                                        rounded-full 
                                        text-lg 
                                        px-4 py-1
                                        mr-4
                                        hover:bg-white
                                        hover:text-blue-500
                                        hover:border-blue-500
                                        font-body normal-case
                                        text-black
                                        font-thin
                                        border
                                        border-black"
                                        variant="filled"
                                        disabled={!(isValid && dirty)}
                                        onClick={() => setShowTOTPDialog(false)}>
                                    Submit
                                </Button>
                            </div>
                        </DialogFooter>
                    </Form>
                )}
            </Formik>
        </Dialog>
        </>
    );
}
