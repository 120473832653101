import React, { useEffect, useState } from 'react';
import { Button, Option, Select, TabPanel, 
    Collapse, Typography, Card, CardBody, List, ListItem} from '@material-tailwind/react';
import { toast } from 'react-toastify';
import { fetcherSimple } from 'utils/api';

export default function AssistTab({ tabValue, survey, surveyId, questionResponsesSentiment,
    keyphrasesSentiment, respondentsResponsesSentiment, setShowRespondentResponseSentimentListDialog,
    setResponseSentimentListId, respondentSentimentData, questionTotalSentimentData, keyphraseBySentiment, sentimentByKeyphrase,
    setThemeEdit, setShowThemeDialog, questionSentimentIndex, setQuestionSentimentIndex, keyphraseSentiment, setKeyphraseSentiment,
    setShowResponsesSentimentListDialog, setResponseSentimentIdList
 }) {
    //Collapse Controls
    const [collapseMainInfo, setCollapseMainInfo] = useState(false);
    const [collapseSentimentAnalysis, setCollapseSentimentAnalysis] = useState(false);
    const [collapseRespondentsSentiment, setCollapseRespondentsSentiment] = useState(false);
    const [collapseKeyphrase, setCollapseKeyphrase] = useState(false);
    const [collapseQuestionSentiment, setCollapseQuestionSentiment] = useState(false);   

    useEffect(() => {
        if (keyphrasesSentiment) {
            console.log("keyphrases:")
            console.log(keyphrasesSentiment)
        }
        if (questionResponsesSentiment) {
            console.log("questionResponsesSentiment:")
            console.log(questionResponsesSentiment)
        }
        if (respondentsResponsesSentiment) {
            console.log("respondentsResponsesSentiment:")
            console.log(respondentsResponsesSentiment)
        }
        if (keyphraseBySentiment) {
            console.log("keyphraseBySentiment:")
            console.log(keyphraseBySentiment)
        }
        if (sentimentByKeyphrase) {
            console.log("sentimentByKeyphrase:")
            console.log(sentimentByKeyphrase)
        }
        if (questionTotalSentimentData) {
            console.log("questionTotalSentimentData:")
            console.log(questionTotalSentimentData)
        }
    }, [keyphrasesSentiment, questionResponsesSentiment, respondentsResponsesSentiment, keyphraseBySentiment, 
        sentimentByKeyphrase, questionTotalSentimentData
    ]);

    const makeKeyphraseTheme = async (keyphrase_text) => {
        setThemeEdit({name: keyphrase_text});
        setShowThemeDialog(true);
    }

    const percentFormat = (decimal) => {
        let formattedPercent;
        if (Number.isFinite(decimal)) {
            formattedPercent = Intl.NumberFormat('default', {
                style: 'percent',
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
            }).format(decimal);
        } else {
            formattedPercent = "N/A";
        }
        return formattedPercent;
    }

    const sentimentByKeyPhraseFiltered = (sentimentList, chosenSentiment) => {
        let sentimentListProcessed;
        if (chosenSentiment) {
            sentimentListProcessed = sentimentList?.[chosenSentiment];
        } else {
            let sentimentArray = [];
            Object.keys(sentimentList).forEach(key => {
                const keyphrases = sentimentList[key];
                const keyphrasesSentimented = keyphrases.map(k => ({...k, overall_sentiment: key}))
                sentimentArray.push(...keyphrasesSentimented)
            });
            sentimentListProcessed = sentimentArray;
        }
        return sentimentListProcessed;
    }

    const sentimentRangeFormat = (responses, score_type) => {
        const max = responses.reduce((prev, current) => (prev && prev[score_type] > current[score_type]) ? prev : current);
        const min = responses.reduce((prev, current) => (prev && prev[score_type] < current[score_type]) ? prev : current);
        let string = "N/A";
        if (max[score_type] && min[score_type] && (min[score_type] !== max[score_type])) {
            string = `${percentFormat(min[score_type]).slice(0, -1)} — ${percentFormat(max[score_type])}`;
        } else if (max) {
            string = `${percentFormat(max[score_type])}`;
        } else if (min) {
            string = `${percentFormat(min[score_type])}`;

        }
        return string;
    }

    const updateComprehend = async () => {
        const response = await fetcherSimple(`/v2/survey/${surveyId}/update_comprehend`, 'POST');
        if (response) {
            toast.success("Updating Analysis!");
            if (response.name) window.location.reload();
        } else {
            toast.warn("Server error trying to process Comprehend!")
        }
    };

    return (
<>
<TabPanel value={tabValue} className=''>
    <div className="">
        <div className='rounded-lg bg-white pl-2 border py-4 shadow'>
            <Typography
                variant="h3"
                className="text-blue-gray-700 font-display w-2/3">
                Insights, Charts, Raw data and Natural Language Processing
            </Typography>
            <Typography>
                <Button
                    type="button"
                    variant="filled"
                    size="lg"
                    className="
                        hover:bg-blue-gray-500 
                        bg-blue-gray-100 
                        hover:text-white
                        text-blue-gray-700
                        font-body
                        normal-case
                        px-4
                        py-2
                        rounded-full
                        mt-2
                        "
                    onClick={() => setCollapseMainInfo(collapseMainInfo => !collapseMainInfo)}>
                    Show/Hide Explanation
                </Button>
            </Typography>
            <Collapse open={collapseMainInfo}>
                <Card className='text-blue-gray-700 text-lg shadow-lg border border-grey-100 mx-4 my-2'>
                    <CardBody className=''>
                        <Typography className='font-body text-blue-gray-700 mt-4'>
                            The Reports section of your survey is split into several sections that will give you a greater understanding of some
                            of the data that has been collected. It is not a comprehensive analysis but a starting point for a deeper understanding.<br /><br />
                            The charts are live from the moment the survey is launched and will update automatically. If you have enabled Amazon
                            Comprehend to help with your manual Theming in Theme Manager it will automatically update as the survey is completed
                            by your survey recipients. Don't worry if you started your survey without enabling Amazon Comprehend's natural language processing as 
                            you can send your survey to this service after you have closed it.<br /><br />
                            Note that uncompleted surveys and partially completed questions are normally added to some insights and charts, especially
                            for processing using ML and NLP at the end of the survey once it has been closed.
                        </Typography>
                        <Typography className='font-body text-blue-900 pt-6 pl-6'>
                            &bull; 'Insights' is a quick overview of the most pertinent information we can determine from your survey. This is presented in a 
                            narrative format with some automatically derived statistics.<br /><br />
                            &bull; Charts are split into 'General Data', 'Themes Data' and 'Demographic Data'. 
                            Charts are live views of the data collected in your survey results. Use the selection box at the top of the 'Demographic Data' section
                            to change which question you see the results for (note that this only applies to surveys with multiple questionsthat are marked as demographic data).<br /><br />
                            &bull; The data downloads are the raw data available in a selection of output variations. This data is a direct snapshot of what is in the survey database at the time of pressing the button.
                            All data is collated and presented in CSV format.<br /><br />
                            &bull; The sections on 'Sentiment Analysis', 'Respondents Sentiment', 'Keyphrases and Keyphrase Sentiment' and 'Sentiment by Question'; display 
                            information returned when Amazon Comprehend has been selected in the survey settings. Or when the data is sent to Amazon Comprehend when a survey
                            is completed. This data will allow an insight into the sentiment of the language used, as well as pulling out keyphrases.<br />
                            The sentiment is given a score to indicate the percentage of confidence (the higher the percentage the more likely the sentiment is used). Further 
                            breakdowns have been provided to show the sentiment displayed by an individual survey respondent and the number of each type of sentiment used 
                            by question; these allow some insight into the general sentiment bias of each.
                            <br />
                        </Typography>
                    </CardBody>
                </Card>
            </Collapse>
        </div>

        <Typography variant="h2" className="text-blue-gray-700 font-display p-2 rounded-lg bg-blue-gray-100 mt-8 pl-2 shadow w-full">
	        Natural Language Processing
        </Typography>

        <div className='rounded-lg bg-white pl-2 border p-4 shadow mt-4'>
            <div className="">
                <Typography as='span' className='my-2 ml-2 font-body text-blue-gray-700'>
                    Pressing the 'Use Amazon Comprehend' button will send the survey responses to for Amazon Comprehend to process. The button is for use in a survey that was previously created but did not have
                    Amazon Comprehend enabled in the survey settings before it was activated and started to receive responses.
                </Typography>
                <Typography className='flex justify-end'>
                    <Button
                        type="button"
                        size="lg"
                        color="white"
                        className="
                        rounded-full
                        px-4 py-2 
                        text-sm
                        border
                        border-gray-500
                        hover:text-blue-500
                        hover:border-blue-500
                        font-body font-thin normal-case
                        mr-4 mb-4"
                        variant="filled"
                        onClick={() => updateComprehend()}>
                        Use Amazon Comprehend
                    </Button>
                </Typography>
            </div>
        </div>

        <div className="p-2 rounded-lg bg-white border mt-6 shadow w-full">
            <Typography
                variant='h4'
                className='ml-2 text-blue-gray-700 w-2/3'>
                    Sentiment Analysis
                </Typography>
            <Button
                type="button"
                variant="filled"
                size="lg"
                className="
                    hover:bg-blue-gray-500 
                    bg-blue-gray-100 
                    hover:text-white
                    text-blue-gray-700
                    font-body
                    normal-case
                    px-4
                    py-2
                    rounded-full
                    mt-2
                    "
                onClick={() => setCollapseSentimentAnalysis(collapsSentimentAnalysis => !collapseSentimentAnalysis)}>
                Show/Hide Explanation
            </Button>
            <Collapse open={collapseSentimentAnalysis}>
                <Typography as="span" className='mb-6 ml-2'>
                    COLLAPSE INFO
                </Typography>
            </Collapse>
            <div className='p-2 my-4'>
                <div className='min-h-[24vh]'>
                    {(questionResponsesSentiment &&
                    <>
                        <Select label="Click here to select a question from the survey"
                        value={questionSentimentIndex?.toString()}
                        onChange={(e) => {
                            const indexInt = parseInt(e);
                            setQuestionSentimentIndex(indexInt);
                        }}>
                            {questionResponsesSentiment.map((q, index) => (
                            <Option key={q.id} value={`${index}`}>Question {q.position}: {q.text}</Option>
                            ))}
                        </Select>
                        <table className="w-full table-auto text-left border-b border-blue-gray-500 mt-2">
                            <thead className='text-left border-b border-gray-500 text-blue-gray-700'>
                                <tr className="pt-2">
                                    <th className='border-b-2 border-r-white border-r-4 border-blue-gray-500 bg-blue-gray-200 px-2 py-2 font-body text-blue-gray-700 rounded-t-lg w-5/12'>Gobby® Response</th>
                                    <th className='border-b-2 border-r-white border-r-4 border-blue-gray-500 bg-blue-gray-200 px-2 py-2 font-body text-blue-gray-700 rounded-t-lg text-center w-2/12'>Sentiment</th>
                                    <th className='border-b-2 border-r-white border-r-4 border-blue-gray-500 bg-blue-gray-200 px-2 py-2 font-body text-blue-gray-700 rounded-t-lg text-center w-1/12'>Mixed</th>
                                    <th className='border-b-2 border-r-white border-r-4 border-blue-gray-500 bg-blue-gray-200 px-2 py-2 font-body text-blue-gray-700 rounded-t-lg text-center w-1/12'>Positive</th>
                                    <th className='border-b-2 border-r-white border-r-4 border-blue-gray-500 bg-blue-gray-200 px-2 py-2 font-body text-blue-gray-700 rounded-t-lg text-center w-1/12'>Negative</th>
                                    <th className='border-b-2 border-blue-gray-500 bg-blue-gray-200 px-2 py-2 font-body text-sm text-blue-gray-700 rounded-t-lg text-center w-1/12'>Neutral</th>
                                </tr>
                            </thead>
                            <tbody className='text-blue-gray-700 text-right'>
                                {questionResponsesSentiment?.[questionSentimentIndex]?.answers_with_sentiment.map((answer, index) => (
                                    <tr className='border-b border-gray-300 text-blue-gray-700 even:bg-blue-gray-50/50' key={answer.id}>
                                        <td className='font-body text-left'>{answer.text}</td>
                                        <td  className='text-center font-body'>{answer.sentiment.sentiment}</td>
                                        <td>
                                            <span className={`text-right font-body px-2 ${answer.sentiment.score === answer.sentiment.mixed_score ?  'font-bold bg-blue-200 rounded-full text-center' : '' }`}>
                                                {percentFormat(answer.sentiment.mixed_score)}
                                            </span>
                                        </td>
                                        <td>
                                            <span className={`text-right font-body px-2 ${answer.sentiment.score === answer.sentiment.positive_score ?  'font-bold bg-blue-200 rounded-full text-center' : '' }`}>
                                                {percentFormat(answer.sentiment.positive_score)}
                                            </span>
                                        </td>
                                        <td>
                                            <span className={`text-right font-body px-2 ${answer.sentiment.score === answer.sentiment.negative_score ?  'font-bold bg-blue-200 rounded-full text-center' : '' }`}>
                                                {percentFormat(answer.sentiment.negative_score)}
                                            </span>
                                        </td>
                                        <td>
                                            <span className={`text-right font-body px-2 ${answer.sentiment.score === answer.sentiment.neutral_score ?  'font-bold bg-blue-200 rounded-full text-center' : '' }`}>
                                                {percentFormat(answer.sentiment.neutral_score)}
                                            </span>
                                        </td>
                                    </tr>
                                )
                                )}
                            </tbody>
                        </table>
                    </>
                    )}
                </div>
            </div>
        </div>

        <div className="p-2 rounded-lg bg-white border mt-6 shadow w-full">
            <Typography
                variant='h4'
                className='ml-2 text-blue-gray-700 w-2/3'>
                    Respondents Sentiment
            </Typography>
            <Button
                type="button"
                variant="filled"
                size="lg"
                className="
                    hover:bg-blue-gray-500 
                    bg-blue-gray-100 
                    hover:text-white
                    text-blue-gray-700
                    font-body
                    normal-case
                    px-4
                    py-2
                    rounded-full
                    mt-2
                    "
                onClick={() => setCollapseRespondentsSentiment(collapseRespondentsSentiment => !collapseRespondentsSentiment)}>
                Show/Hide Explanation
            </Button>
            <Collapse open={collapseRespondentsSentiment}>
                <Typography as="span" className='mb-6 ml-2'>
                    COLLAPSE INFO
                </Typography>
            </Collapse>
            <div className='p-2 mt-2'>
                {(respondentSentimentData && 
                    <table className="table-auto w-full text-left border-b border-blue-gray-500">
                        <thead className='text-center border-b border-gray-500 text-blue-gray-700'>
                            <tr>
                                <th className='border-b-2 border-r-white border-r-4 border-blue-gray-500 bg-blue-gray-200 px-2 py-2 font-body text-blue-gray-700 rounded-t-lg w-2/12'>Respondent ID</th>
                                <th className='border-b-2 border-r-white border-r-4 border-blue-gray-500 bg-blue-gray-200 px-2 py-2 font-body text-blue-gray-700 rounded-t-lg w-1/12'>Mixed</th>
                                <th className='border-b-2 border-r-white border-r-4 border-blue-gray-500 bg-blue-gray-200 px-2 py-2 font-body text-blue-gray-700 rounded-t-lg w-1/12'>Positive</th>
                                <th className='border-b-2 border-r-white border-r-4 border-blue-gray-500 bg-blue-gray-200 px-2 py-2 font-body text-blue-gray-700 rounded-t-lg w-1/12'>Neutral</th>
                                <th className='border-b-2 border-r-white border-r-4 border-blue-gray-500 bg-blue-gray-200 px-2 py-2 font-body text-blue-gray-700 rounded-t-lg w-1/12'>Negative</th>
                                <th className='border-b-2 border-r-white border-r-4 border-blue-gray-500 bg-blue-gray-200 px-2 py-2 font-body text-blue-gray-700 rounded-t-lg w-1/2'>Respondent Answers</th>
                            </tr>
                        </thead>
                        <tbody className='text-blue-gray-700'>
                            {respondentSentimentData.map((respondent, index) => (
                                <tr className='font-body text-center border-b border-gray-300 text-blue-gray-700 even:bg-blue-gray-50/50' key={respondent.respondent_id}>
                                    <td className='text-center'>{respondent.respondent_id}</td>
                                    <td>
                                        <span className={`font-body px-2 ${respondent.respondent_sentiment.mixed === respondent.respondent_sentiment.highest ?  'font-bold bg-green-200 rounded-full text-center' : '' }`}>
                                            {respondent.respondent_sentiment.mixed}
                                        </span>
                                    </td>                                                    
                                    <td>
                                        <span className={`font-body px-2 ${respondent.respondent_sentiment.positive === respondent.respondent_sentiment.highest ?  'font-bold bg-green-200 rounded-full text-center' : '' }`}>

                                        </span>
                                        {respondent.respondent_sentiment.positive}
                                    </td>
                                    <td>
                                        <span className={`font-body px-2 ${respondent.respondent_sentiment.neutral === respondent.respondent_sentiment.highest ?  'font-bold bg-green-200 rounded-full text-center' : '' }`}>
                                            {respondent.respondent_sentiment.neutral}
                                        </span>
                                    </td>
                                    <td>
                                        <span className={`font-body px-2 ${respondent.respondent_sentiment.negative === respondent.respondent_sentiment.highest ?  'font-bold bg-green-200 rounded-full text-center' : '' }`}>
                                            {respondent.respondent_sentiment.negative}
                                        </span>
                                    </td>
                                    <td className='text-center'>
                                        <Button type="button"
                                            variant="filled"
                                            color='white'
                                            className="
                                                rounded-full 
                                                text-sm
                                                border
                                                border-gray-500
                                                sm:px-2 sm:py-1
                                                px-2 py-1
                                                hover:border-1
                                                hover:border-blue-500
                                                hover:text-blue-500
                                                font-body font-thin normal-case
                                                "
                                            onClick={() => {
                                                setResponseSentimentListId(index);
                                                setShowRespondentResponseSentimentListDialog(true);
                                            }}>
                                            See Responses
                                        </Button>
                                    </td>                                                    
                                </tr>
                            )
                            )}
                        </tbody>
                    </table>
                )}
            </div>
        </div>

        <div className="p-2 rounded-lg bg-white border mt-6 shadow w-full">
            <Typography
                variant='h4'
                className='ml-2 text-blue-gray-700 w-2/3'>
                    Keyphrases and Keyphrase Sentiment
            </Typography>
            <Button
                type="button"
                variant="filled"
                size="lg"
                className="
                    hover:bg-blue-gray-500 
                    bg-blue-gray-100 
                    hover:text-white
                    text-blue-gray-700
                    font-body
                    normal-case
                    px-4
                    py-2
                    rounded-full
                    mt-2
                    "
                onClick={() => setCollapseKeyphrase(collapseKeyphrase => !collapseKeyphrase)}>
                Show/Hide Explanation
            </Button>
            <Collapse open={collapseKeyphrase}>
                <Typography as="span" className='mb-6 ml-2'>
                    <Card className='text-blue-gray-700 text-lg shadow-lg border border-grey-100'>
                        <CardBody className=''>
                            <div className=''>
                                <Typography className='font-body'>
                                The table below shows a sentiment score for the keyphrases deduced from the survey respondents answers.
                                Where we show sentiment scores it is taken from a division into how the key phrase is divided by the following sentiments: 
                                positive, negative, mixed, neutral. It is important to note that these are not scores of a good or bad, just the language sentiment. 
                                It is split into the following columns:
                                </Typography>
                                <List className='font-body py-0 text-blue-800'>
                                    <ListItem>&#9679; Keyphrase: The keyphrase that Amazon Comprehend has identified in the text</ListItem>
                                    <ListItem>&#9679; Sentiment: This shows the highest scoring sentiment type(s)</ListItem>
                                    <ListItem>&#9679; Key Sentiment Range: This shows the range of sentiment strength for the key sentiment by percentage.</ListItem>
                                    <ListItem>&#9679; Responses: Press this button to see all the responses that share this keyphrase with their individual scores</ListItem>
                                    <ListItem>&#9679; Make Theme: Turn this keyphrase into a theme for theming your survey.</ListItem>
                                </List>
                                <Typography className='font-body'>
                                    The table can be viewed for all Keyphrase response sentiments (combined or "All") or by sentiment type (positive, negative, mixed, neutral) by using the drop down at the top of the table.
                                </Typography>
                                <Typography className='mt-2 font-body text-blue-gray-700'>
                                    Keyphrase analysis provided by Amazon Comprehend LLM.<br />If there are multiple sentiments 
                                    to a keyword, it will show the range from min to max.
                                </Typography>
                            </div>
                        </CardBody>
                    </Card>
                </Typography>
            </Collapse>
            {/* Min Height set here to avoid hiding dropdown select if no list */}
            <div className='pt-8 min-h-[28vh]'>
                {(sentimentByKeyphrase &&
                <>
                    <Select label="Select a sentiment type  (positive, neutral, mixed or negative)" 
                        value={keyphraseSentiment} onChange={(value) => {
                        setKeyphraseSentiment(value);
                    }}>
                        <Option value="">All</Option>
                        <Option value="positive">Positive</Option>
                        <Option value="neutral">Neutral</Option>
                        <Option value="negative">Negative</Option>
                        <Option value="mixed">Mixed</Option>
                    </Select>
                    <table className="w-full table-auto text-left border-b border-blue-gray-500 mt-2">
                        <thead className='text-left border-b border-gray-500 text-blue-gray-700'>
                            <tr className="pt-2">
                                <th className='border-b-2 border-r-white border-r-4 border-blue-gray-500 bg-blue-gray-200 px-2 py-2 font-body text-blue-gray-700 rounded-t-lg w-4/12'>Keyphrase</th>
                                <th className='border-b-2 border-r-white border-r-4 border-blue-gray-500 bg-blue-gray-200 px-2 py-2 font-body text-blue-gray-700 rounded-t-lg text-center w-2/12'>Key Sentiment</th>
                                <th className='border-b-2 border-r-white border-r-4 border-blue-gray-500 bg-blue-gray-200 px-2 py-2 font-body text-blue-gray-700 rounded-t-lg text-center w-2/12'>Key Sentiment Range</th>
                                <th className='border-b-2 border-r-white border-r-4 border-blue-gray-500 bg-blue-gray-200 px-2 py-2 font-body text-blue-gray-700 rounded-t-lg w-2/2'>Responses</th>
                                <th className='border-b-2 border-r-white border-r-4 border-blue-gray-500 bg-blue-gray-200 px-2 py-2 font-body text-blue-gray-700 rounded-t-lg text-center w-2/12'>Make Theme</th>
                            </tr>
                        </thead>
                        <tbody className='text-blue-gray-700 text-right'>
                            {sentimentByKeyPhraseFiltered(sentimentByKeyphrase, keyphraseSentiment)?.map((keyphrase, index) => (
                            <tr className='border-b border-gray-300 text-blue-gray-700 even:bg-blue-gray-50/50' key={index}>
                                <td className='font-body text-left'>{keyphrase.text}</td>
                                <td  className='text-center font-body capitalize'>
                                    {keyphrase.overall_sentiment}
                                </td>
                                <td  className='text-center font-body'>
                                    {sentimentRangeFormat(keyphrase.responses, "keyphrase_score")}
                                </td>
                                <td className='text-center'>
                                    <Button type="button"
                                        variant="filled"
                                        color='white'
                                        className="
                                            rounded-full 
                                            text-sm
                                            border
                                            border-gray-500
                                            sm:px-2 sm:py-1
                                            px-2 py-1
                                            hover:border-1
                                            hover:border-blue-500
                                            hover:text-blue-500
                                            font-body font-thin normal-case
                                            my-1"
                                        onClick={() => {
                                            const responseIdList = keyphrase.responses?.map(r => r?.respondent_answer_id);
                                            setResponseSentimentIdList(responseIdList);
                                            setShowResponsesSentimentListDialog(true);
                                        }}>
                                        See Responses
                                    </Button>
                                </td>
                                <td className='text-center'>
                                    <Button type="button"
                                        variant="filled"
                                        color='white'
                                        className="
                                            rounded-full 
                                            text-sm
                                            border
                                            border-gray-500
                                            sm:px-2 sm:py-1
                                            px-2 py-1
                                            hover:border-1
                                            hover:border-blue-500
                                            hover:text-blue-500
                                            font-body font-thin normal-case
                                            my-1"
                                        onClick={() => {
                                            makeKeyphraseTheme(keyphrase.text)
                                        }}>
                                            Make into a Theme
                                    </Button>
                                </td>
                            </tr>
                            )
                            )}
                        </tbody>
                    </table>
                </>
                )}
            </div>
        </div>

        <div className="p-2 rounded-lg bg-white border mt-6 shadow w-full">
            <Typography
                variant='h4'
                className='ml-2 text-blue-gray-700 w-2/3'>
                    Sentiment by Question
            </Typography>
            <Button
                type="button"
                variant="filled"
                size="lg"
                className="
                    hover:bg-blue-gray-500 
                    bg-blue-gray-100 
                    hover:text-white
                    text-blue-gray-700
                    font-body
                    normal-case
                    px-4
                    py-2
                    rounded-full
                    mt-2
                    "
                onClick={() => setCollapseQuestionSentiment(collapseQuestionSentiment => !collapseQuestionSentiment)}>
                Show/Hide Explanation
            </Button>
            <Collapse open={collapseQuestionSentiment}>
                <Typography as="span" className='mb-6 ml-2'>
                Lists the question number; question text; and number of each type of sentiment given by respondent answers. The most significant sentiment, by count, is highlighted.
                </Typography>
            </Collapse>
            <div className='p-2 mb-2'>
                <div className='border border-t-blue-gray-700 pt-4 boder-2'>
                {(questionTotalSentimentData &&
                <>
                <table className="w-full table-auto text-left border-b border-blue-gray-500 mt-2">
                    <thead className='text-left border-b border-gray-500 text-blue-gray-700'>
                        <tr className="pt-2">
                            <th className='border-b-2 border-r-white border-r-4 border-blue-gray-500 bg-blue-gray-200 px-2 py-2 font-body text-blue-gray-700 rounded-t-lg w-1/12'>Position</th>
                            <th className='border-b-2 border-r-white border-r-4 border-blue-gray-500 bg-blue-gray-200 px-2 py-2 font-body text-blue-gray-700 rounded-t-lg text-left w-7/12'>Text</th>
                            <th className='border-b-2 border-r-white border-r-4 border-blue-gray-500 bg-blue-gray-200 px-2 py-2 font-body text-blue-gray-700 rounded-t-lg text-center w-1/12'>Mixed</th>
                            <th className='border-b-2 border-r-white border-r-4 border-blue-gray-500 bg-blue-gray-200 px-2 py-2 font-body text-blue-gray-700 rounded-t-lg text-center w-1/12'>Positive</th>
                            <th className='border-b-2 border-r-white border-r-4 border-blue-gray-500 bg-blue-gray-200 px-2 py-2 font-body text-blue-gray-700 rounded-t-lg text-center w-1/12'>Negative</th>
                            <th className='border-b-2 border-blue-gray-500 bg-blue-gray-200 px-2 py-2 font-body text-sm text-blue-gray-700 rounded-t-lg text-center w-1/12'>Neutral</th>
                        </tr>
                    </thead>
                    <tbody className='text-blue-gray-700 text-right'>
                        {questionTotalSentimentData?.map((question, index) => (
                            <tr className='border-b border-gray-300 text-blue-gray-700 even:bg-blue-gray-50/50 text-right' key={question.question_id}>
                                <td className='font-body text-center'>{question.position}</td>
                                <td  className='text-left font-body'>{question.text}</td>
                                <td>
                                    <span className={`text-right font-body px-2 ${question.respondent_sentiment.highest === question.respondent_sentiment.mixed ?  'font-bold bg-blue-200 rounded-full text-center' : '' }`}>
                                        {question.respondent_sentiment.mixed}
                                    </span>
                                </td>
                                <td>
                                    <span className={`font-body px-2 ${question.respondent_sentiment.highest === question.respondent_sentiment.positive ?  'font-bold bg-blue-200 rounded-full text-center' : '' }`}>
                                        {question.respondent_sentiment.positive}
                                    </span>
                                </td>
                                <td>
                                    <span className={`font-body px-2 ${question.respondent_sentiment.highest === question.respondent_sentiment.negative ?  'font-bold bg-blue-200 rounded-full text-center' : '' }`}>
                                        {question.respondent_sentiment.negative}
                                    </span>
                                </td>
                                <td>
                                    <span className={`font-body px-2 ${question.respondent_sentiment.highest === question.respondent_sentiment.neutral ?  'font-bold bg-blue-200 rounded-full text-center' : '' }`}>
                                        {question.respondent_sentiment.neutral}
                                    </span>
                                </td>
                            </tr>
                        )
                        )}
                    </tbody>
                </table>
                </>
                )}
                </div>
            </div>
        </div>
    </div>
</TabPanel>
</>
    );
}
