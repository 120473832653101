import React from 'react';
import {Button, Dialog, DialogHeader, DialogBody, DialogFooter, Option, Select, Typography} from '@material-tailwind/react';
import { FieldArray, Form, Formik } from 'formik';
import FormikCheckbox from 'components/form/formik-checkbox';

export default function ThemeAnswersDialog({showThemeAnswersDialog, setShowThemeAnswersDialog, themeEdit, handleThemeAnswersSubmit,
    validationSchemaThemeAnswers, themeAnswersThemesState, setThemeAnswersThemesState, surveyQuestions, surveyRespondentAnswers, 
    modQShown, setModQShown, themeAnswers}) {
    
    return (
        <Dialog open={showThemeAnswersDialog} handler={() => setShowThemeAnswersDialog(showAnswerThemingDialog=>!showAnswerThemingDialog)}>
            <DialogHeader className="bg-gray-300 text-black font-display rounded-t-lg">
                Apply Theme
            </DialogHeader>
            <Formik initialValues={{
                    theme: themeEdit?.id || 0,
                    answers_preset: themeAnswers?.theme_preset_answers.map(e => e.preset_answer_id) || [],
                    answers_custom: themeAnswers?.theme_respondent_answers.map(e => e.respondent_answer_id) || [],
                }} onSubmit={handleThemeAnswersSubmit} validationSchema={validationSchemaThemeAnswers}>
                {({isValid, dirty, values}) => (
                    <Form>
                        <Typography className='px-4 pt-2 font-body'>Apply this theme to any/all answers to a particular question. First choose an answer from either
                                                                    Gobby® Response or a preset answers question from the list</Typography>
                        <DialogBody className='font-body overflow-y-auto min-h-[40vh] max-h-[60vh]'>
                        <div className="flex flex-row-reverse mb-4">
                                <Button onClick={() => setThemeAnswersThemesState('preset')}
                                    color={themeAnswersThemesState === 'response' ? 'white' : 'white'}
                                    className="
                                    rounded-full
                                    px-2 py-2 
                                    text-sm
                                    border
                                    border-gray-500
                                    hover:text-blue-500
                                    hover:border-blue-500
                                    font-body font-thin normal-case
                                    mr-4 mb-2"
                                    variant="filled">
                                    Preset Answers
                                </Button>
                                {(surveyRespondentAnswers &&    
                                <Button onClick={() => setThemeAnswersThemesState('response')}
                                    color={themeAnswersThemesState === 'response' ? 'white' : 'white'}
                                    className="
                                    rounded-full
                                    px-2 py-2 
                                    text-sm
                                    border
                                    border-gray-500
                                    hover:text-blue-500
                                    hover:border-blue-500
                                    font-body font-thin normal-case
                                    mr-4 mb-2"
                                    variant="filled">
                                    Gobby® Response
                                </Button>
                                )}
                            </div>
                            {(surveyQuestions &&
                            <Select label="Select a question from the list" value={modQShown?.toString()} onChange={(e) => {
                                const indexInt = parseInt(e);
                                setModQShown(indexInt);
                            }}>
                                {surveyQuestions.map((q, index) => (
                                    <Option key={q.id} value={`${index}`}>Question {q.position}: {q.text}</Option>
                                ))}
                            </Select>
                            )}
                            {(themeAnswersThemesState === 'preset' &&
                            <FieldArray name="answers_preset">
                            {({push, remove}) => (
                                <table className='font-body md:table-fixed border-collapse w-full'>
                                    <thead className='text-left text-lg border-b border-gray-500 text-blue-gray-700'>
                                        <tr>
                                            <th className='w-5/6 w-pl-1 pt-4'>Preset Response</th>
                                            <th className='w-1/6 pt-4'>Theme</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {surveyQuestions[modQShown]?.preset_answers.map((answer, index) => (
                                        <tr className='border-b border-gray-300 text-blue-gray-700 odd:bg-gray-100' key={answer.id}>
                                            <td className=''>{answer.text}</td>
                                            <td className='text-right md:pr-4'>
                                                <FormikCheckbox name="answers_preset" type="checkbox" 
                                                    value={answer.id} checked={values.answers_preset.includes(answer.id)}
                                                    onChange={e => {
                                                        if (e.target.checked) {
                                                            push(answer.id);
                                                        } else {
                                                            const idx = values.answers_preset.indexOf(answer.id);
                                                            remove(idx);
                                                        }
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                        ))}
                                    </tbody>
                            </table>
                            )}
                            </FieldArray>
                            )}
                            {(themeAnswersThemesState === 'response' &&
                            <FieldArray name="answers_custom">
                            {({push, remove}) => (
                                <table className='font-body md:table-fixed border-collapse w-full'>
                                    <thead className='text-left text-lg border-b border-gray-500 text-blue-gray-700'>
                                        <tr>
                                            <th className='w-5/6 pl-1 pt-4'>Gobby® Response</th>
                                            <th className='w-1/6 pt-4'>Theme</th>
                                        </tr>
                                    </thead>
                                    <tbody className=' text-blue-gray-700'>
                                        {surveyRespondentAnswers?.[modQShown]?.respondent_answers.map((answer, index) => (
                                            <tr className='border-b border-gray-300 text-blue-gray-700 odd:bg-gray-100' key={answer.id}>
                                                <td className=''>{answer.text}</td>
                                                <td className='text-right md:pr-4'>
                                                    <FormikCheckbox name="answers_custom" type="checkbox" 
                                                        value={answer.id} checked={values.answers_custom.includes(answer.id)}
                                                        onChange={e => {
                                                            if (e.target.checked) {
                                                                push(answer.id);
                                                            } else {
                                                                const idx = values.answers_custom.indexOf(answer.id);
                                                                remove(idx);
                                                            }
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                            </FieldArray>
                            )}
                        </DialogBody>
                        <DialogFooter className="">
                            <div className=''>
                                <Button type="button" 
                                    color='white'
                                    className='
                                    mr-4 
                                    hover:border-1
                                    hover:border-blue-500
                                    hover:text-blue-500
                                    border 
                                    border-gray-700 
                                    px-6 py-2 
                                    rounded-full' 
                                    variant="filled"
                                    size="lg" 
                                    onClick={() => setShowThemeAnswersDialog(false)}>
                                    Cancel
                                </Button>
                                <Button type="submit" 
                                    disabled={!(isValid && dirty)} 
                                    onClick={() => setShowThemeAnswersDialog(false)}
                                    color='white'
                                    className='
                                    mr-4 
                                    hover:border-1
                                    hover:border-blue-500
                                    hover:text-blue-500
                                    border 
                                    border-gray-700 
                                    px-6 py-2 
                                    rounded-full' 
                                    variant="filled"
                                    size="lg">
                                    Save
                                </Button>
                            </div>
                        </DialogFooter>
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}
